import { useContext } from "react";
import ScrollToSubscribeContext from "../ScrollToSubscribeContext";

import {
  MdSportsVolleyball,
  MdAssistantNavigation,
  MdSupervisedUserCircle,
  MdInfo,
  MdAddAlert,
} from "react-icons/md";

const Features = () => {
  const [, , scrollToSubscribe] = useContext(ScrollToSubscribeContext);

  const featuresContent = [
    {
      id: 1,
      icon: <MdSportsVolleyball size={40} color="rgb(255 187 32)" />,
      title: "Sporto šakos",
      text:
        "Planuok savo aktyvų laiką pagal mėgstamiausią sporto užsiėmimą. Pasirink sporto šaką " +
        "ir pamatyk vykstančius sporto įvykius."
    },
    {
      id: 2,
      icon: <MdAssistantNavigation size={40} color="rgb(255 187 32)" />,
      title: "Atstumas iki aikštelės",
      text:
        "Rask žaidimą arčiausiai savęs. Žemėlapio funkcija leis matyti atstumą " +
        "iki vietos, tad nesunkiai pasieksi bendraminčius."
    },
    {
      id: 3,
      icon: <MdSupervisedUserCircle size={40} color="rgb(255 187 32)" />,
      title: "Žaidėjo lygis",
      text:
        "Įvertink savo galimybes bei prisijunk prie kompanijos, kurioje jausiesi puikiai. " +
        "Žaidėjo lygio funkcija užtikrins tikslų komandos narių ar priešininkų pasirinkimą.",
    },
    {
      id: 4,
      icon: <MdAddAlert size={40} color="rgb(255 187 32)" />,
      title: "Laisvos vietos",
      text:
        "Matyk likusių vietų skaičių ir dalyvaujančių žaidėjų sąrašą. Junkis, kviesk draugus ir " +
        "nepraleisk progos susipažinti bei sportuoti su bendraminčiais.",
    },
    {
      id: 5,
      icon: <MdInfo size={40} color="rgb(255 187 32)" />,
      title: "Papildoma informacija",
      text:
        "Reikia inventoriaus, atsiskaitymo galimybės ar kita papildoma informacija? Viską rasi įvykio aprašyme.",
    },
  ];

  return (
    <>
      {/* <!-- Features --> */}
      <section className="relative pt-24 pb-10 dark:bg-jacarta-800">
        <div className="container">
          <div className="lg:flex lg:flex-nowrap">
            <div className="mb-16 lg:w-1/3 lg:pr-20 text-center hidden lg:block">
              <img
                src="/images/app_screen_2.png"
                alt="Shareball create event"
                width="290"
                className="rounded-2.5xl inline-block"
              />
            </div>
            {/* <!-- End Left Image --> */}

            {/* <!-- Info --> */}
            <div className="mb-16 lg:w-2/3 lg:pl-5">
              <h2 className="mb-6 font-display text-3xl text-jacarta-700 dark:text-white">
                Leisk viskuo pasirūpinti Shareball
              </h2>
              <p className="mb-12 text-lg leading-normal dark:text-jacarta-300 font-light">
                Facebook ir visa ta betvarkė jau atgyveno. Daugybė grupių, pasimetantys postai,
                sumaištis komentaruose ir nepasirodantys žaidėjai.
                <br />
                <br />
                Shareball turi sprendimą! Ši platforma sujungia visus sporto mėgėjus
                į vieną bendruomenę. Čia lengvai ir greitai rasi tai, ko ieškai.
              </p>

              <div className="mb-16 lg:w-1/3 lg:pr-20 text-center lg:hidden">
                <img
                  src="/images/app_screen_2.png"
                  alt="Shareball create event"
                  width="290"
                  className="rounded-2.5xl inline-block"
                />
              </div>

              {featuresContent.map((item) => (
                <div className="mb-6 flex space-x-6" key={item.id}>
                  <div className="inline-flex shrink-0 items-center justify-center rounded-full bg-white shadow-2xl dark:bg-jacarta-700">
                    {item.icon}
                  </div>
                  <div>
                    <h3 className="mb-3 mt-2 block font-display text-xl font-semibold text-jacarta-700 dark:text-white">
                      {item.title}
                    </h3>
                    <p className="dark:text-jacarta-300">{item.text}</p>
                  </div>
                </div>
              ))}

            </div>
          </div>
        </div>
      </section>
      {/* <!-- end features -->   */}
    </>
  );
};

export default Features;
