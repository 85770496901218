import SingleFeature from "./SingleFeature";

const featureContent = [
  {
    id: 1,
    meta: "Registruokis",
    textInfo: "Rinkis patogų registracijos būdą ir prisijunk prie aktyvios bendruomenės jau dabar.\n" +
      "Vos keli žingsniai ir tu jau Shareball narys!",
  },
  {
    id: 2,
    meta: "Organizuok",
    textInfo: "Būk lyderis – sukurk sporto įvykį lengvai bei greitai. Pasirink sporto šaką, trūkstamų\n" +
      "žaidėjų skaičių, jų lygį ir nurodyk kainą. Didžiuokis organizacine sėkme bei puikia\n" +
      "kompanija!",
  },
  {
    id: 3,
    meta: "Prisijunk",
    textInfo: "Prisijunk prie sporto renginio ir puikiai praleisk laiką. Visa informacija patogiai\n" +
      "vienoje vietoje – žaidėjų sąrašas, reikiamas lygis, atstumas iki sporto aikštelės ir\n" +
      "kaina.",
  },
  {
    id: 4,
    meta: "Dalinkis",
    textInfo: "Kviesk draugus ir pažįstamus bei dalinkis sporto įvykiu vienu mygtuko paspaudimu.\n" +
      "Kartu tobulėkite sporto aikštelėje, bendraminčių apsuptyje.",
  },
];

const index = () => {
  return (
    <section className="py-4">
      <div className="container">
        <div className="flex flex-col lg:flex-row">
          {featureContent.map((item, index) => (
            <div className="flex flex-col lg:flex-row" key={index}>
              <div className="flex">
                {SingleFeature(item)}
              </div>
              {index < featureContent.length - 1 &&
                <div className="flex place-items-center invisible lg:visible">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#1D1D1D" viewBox="0 0 24 24" strokeWidth="0.1" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                </div>
              }
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default index;
