const SingleFeature = (item) => {
  const colors = {
    bgFill: "bg-[#1D1D1D]",
    bgLight: "bg-[#1D1D1D]",
  };

  return (
    <div
      className="rounded-2.5xl bg-light-base p-8 text-center dark:bg-jacarta-800"
      key={item.id}
    >
      <div
        className={`inline-flex h-12 w-12 items-center justify-center rounded-full ${colors.bgFill} text-white`}
        style={{ backgroundColor: "rgb(255 187 32)" }}
      >
        {item.id}
      </div>
      <h3 className="mb-2 mt-2 font-display text-lg text-jacarta-700 dark:text-white">
        {item.meta}
      </h3>
      <p className="dark:text-jacarta-300">{item.textInfo}</p>
    </div>
  );
};

export default SingleFeature;
