import AppStoreButtons from "../AppStoreButtons";

const Story = () => {
  return (
    <section
      id="subscribe-section"
      className="dark:bg-jacarta-800 relative py-4"
    >
      <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
        <img
          src="/images/gradient_light.jpg"
          alt="gradient"
          className="h-full w-full"
        />
      </picture>
      <div className="container">
        <div className="lg:flex lg:items-center lg:justify-between">
          {/* <!-- Image --> */}
          <div className="lg:w-1/3">
            <div className="relative">
              <img
                src="/images/app_screen_3.png"
                width="290"
                className="mx-auto mt-8 rotate-[0deg]"
              />
            </div>
          </div>
          {/* <!-- Info --> */}
          <div className="pb-10 lg:w-2/3 lg:py-20 lg:pr-16">
            <h2 className="text-jacarta-700 font-display mb-6 text-3xl dark:text-white mt-6">
              Kurk sporto įvykius ir kviesk sportuojančius
            </h2>
            <p className="dark:text-jacarta-300 mb-10 text-lg leading-normal font-light">
              Šiandien puiki diena treniruotei, bet draugai užimti? Su Shareball
              tokių problemų nebeliks. Pasirink sporto šaką, nustatyk lokaciją,
              laiką, kainą, parink žaidėjo lygį ir prie tavęs prisijungs tavo
              bendraminčiai.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Story;
