import { useState, useContext } from "react";
import { event } from "nextjs-google-analytics";

import ScrollToSubscribeContext from "../ScrollToSubscribeContext";

import LoadingIcons from "react-loading-icons";
import AppStoreButtons from "../AppStoreButtons";

const DownloadAppBanner = () => {
  const [subscribeRef] = useContext(ScrollToSubscribeContext);

  return (
    <section className="relative py-10">
      <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
        <img
          src="/images/gradient_light.jpg"
          alt="gradient"
          className="h-full w-full"
        />
      </picture>
      <picture className="pointer-events-none absolute inset-0 -z-10 hidden dark:block">
        <img
          src="/images/gradient_dark.jpg"
          alt="gradient dark"
          className="h-full w-full"
        />
      </picture>
      {/* bg banner */}

      <div
        className="container bg-main pb-8 sm:p-8"
        ref={subscribeRef}
        style={{ borderRadius: "30px" }}
      >
        <div
          className="flex flex-col items-center space-y-10 lg:flex-row lg:space-y-0 lg:space-x-4"
          style={{ justifyContent: "space-around" }}
        >
          <div className="order-1 text-center lg:order-1 lg:w-1/4 lg:self-end xl:w-[30%] mt-8 lg:mt-0">
            <img
              srcSet="/images/bottom-image.png, /images/bottom-image@2x.png 2x"
              className="inline-block"
              alt=""
            />
          </div>

          <div className="lg:order-3 lg:w-2/4">
            <div className="text-center lg:text-left">
              <h2 className="mb-6 font-display text-xl dark:text-white lg:pr-4">
                Nepraleisk progos įšokti į naują trendą,
                <br />
                Shareball jau čia!
              </h2>
            </div>

            <div className="mt-8 text-center lg:text-left">
              <AppStoreButtons gaSectionName="Naujas Ivykis Section" />
            </div>
          </div>
          {/* End :lg=prder-1 */}
        </div>
      </div>
    </section>
  );
};

export default DownloadAppBanner;
