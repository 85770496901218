import { useContext } from "react";
import ScrollToSubscribeContext from "../ScrollToSubscribeContext";
import AppStoreButtons from "../AppStoreButtons";

const Hero_10 = () => {
  const [, , scrollToSubscribe] = useContext(ScrollToSubscribeContext);

  const heroContent = {
    meta: "#1 Sporto bičiulis",
    title: "Mėgautis sportu su puikia komanda dar nebuvo taip paprasta!",
    descriptions:
      "Trūksta žaidėjų komandai? O galbūt esi vienas ir ieškai visos likusios komandos? Kviečiame prisijungti prie savo bendraminčių. Organizuok ar junkis į žaidimą pagal sporto šaką, lygį ir tinkamiausią lokaciją.",
  };

  return (
    <section className="relative pt-20">
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10">
        <img className="w-full" src="/images/gradient.jpg" alt="gradient" />
      </picture>

      <div className="container">
        <div className="h-full items-center gap-4 lg:grid lg:grid-cols-12">
          <div className="flex h-full flex-col items-center justify-center py-10 lg:col-span-7 lg:items-start lg:py-20">
            <div className="mb-8 flex items-center space-x-3 rounded-3xl border border-jacarta-200 px-5 py-2 dark:border-jacarta-300">
              <img
                src="/images/rocket_crypto_app.png"
                alt="cryto app"
                width="20"
                height="20"
              />
              <span className="text-xs font-bold uppercase tracking-wide text-jacarta-500 dark:text-jacarta-100">
                {heroContent.meta}
              </span>
            </div>
            <h1 className="mb-6 text-center font-display text-5xl text-jacarta-700 dark:text-white lg:text-left lg:text-6xl">
              {heroContent.title}
            </h1>
            <p className="mb-8 text-center text-lg dark:text-jacarta-200 lg:text-left leading-normal font-light">
              {heroContent.descriptions}
            </p>
            <AppStoreButtons
              gaSectionName="Naujas Ivykis Section"
              isHoverInverted={true}
            />
          </div>
          {/* End flex-col */}

          {/* <!-- Hero image --> */}
          <div className="col-span-5">
            <div className="relative text-center">
              <img
                src="/images/app_screen_1.png"
                alt="Shareball frontpage"
                className="inline-block"
                width="290"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero_10;
