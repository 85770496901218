import { event } from "nextjs-google-analytics";

function AppStoreButtons({ gaSectionName }) {
  return (
    <div className="flex flex-col sm:flex-row space-x-4 items-center">
      <a
        href="https://apps.apple.com/lt/app/shareball/id6473081270"
        onClick={() => {
          event("Click", {
            category: "Button",
            label: `${gaSectionName} - App Store`,
          });
        }}
      >
        <img
          width="160px"
          src="/images/apple-store-badge.svg"
          className="inline-block sm:mr-2"
          alt="app store"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.shareball.shareball"
        className="mt-2 sm:mt-0"
        onClick={() => {
          event("Click", {
            category: "Button",
            label: `${gaSectionName} - Google Play`,
          });
        }}
      >
        <img
          width="180px"
          src="/images/google-play-badge.png"
          className="inline-block mr-3 sm:mr-2"
          alt="play store"
        />
      </a>
    </div>
  );
}

export default AppStoreButtons;
